// src/components/Layout.tsx

import React, { ReactNode } from "react";
import { Header } from "./common";
import { Helmet } from "react-helmet";

interface LayoutProps {
  location: Location;
  title: string;
  description?: string; // Optional description prop
  children: ReactNode;
}


const Layout: React.FC<LayoutProps> = ({ location, children }) => {

  // Logic for determining if it's the root path can be adjusted as needed
  const rootPath = "/";
  const isRootPath = location.pathname === rootPath;

  return (
    <>
      <Helmet>
        <title>Molecular Execution Machine (MEM) | Blog</title>
        <meta name="description" content='Lightning-fast serverless functions for web3 ⚡' />
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap" rel="stylesheet" />
      </Helmet>
      <div className={`relative antialiased flex flex-col`}>
        <Header />
        <main className="flex-1 bg-[#170a1c] px-8 lg:px-24 py-2 md:py-12 overflow-y-auto flex flex-col justify-center items-center">
         {isRootPath && <h1 className="text-5xl font-bold font-mono text-center">MEM Blog</h1>}
          {children}
        </main>
      </div>
    </>
  );

}
  

export default Layout;
