import React from 'react'
import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

const navigation = [
  //{ name: 'Whitepaper', href: '#' },
  { name: 'Docs', href: 'https://docs.wvm.dev/' },
  { name: 'GitHub', href: 'https://github.com/weaveVM' },
]

const DOCS_URL = "https://docs.mem.tech";
const TWITTER_URL = "https://twitter.com/mem_tech"

const linkStyling =
  "font-sans underline text-sm underline-offset-2 font-light transition-colors duration-300 decoration-1";

const URLS = () => (
  <>
    <div className="flex gap-x-1 md:gap-x-2">
    </div>
    <div className="text-sm flex gap-x-1 md:gap-x-2">
      ✨
      <a href="https://mem.tech/learn" className={linkStyling}>
        Learn
      </a>{" "}
      {/*<span className="bg-primary text-white hover:text-white">New!</span>*/}
    </div>
    <div className="flex gap-x-1 md:gap-x-2">
      <a href="https://mem.tech/features" className={linkStyling}>
        Features
      </a>{" "}
      {/*<span className="bg-primary text-white hover:text-white">New!</span>*/}
    </div>
    <div className="flex gap-x-1 md:gap-x-2">
      <a href="https://mem.tech/use-cases" className={linkStyling}>
        Use cases
      </a>{" "}
      {/*<span className="bg-primary text-white hover:text-white">New!</span>*/}
    </div>
    <div className="flex gap-x-1 md:gap-x-2">
      <a href="https://blog.mem.tech" className={linkStyling}>
        Blog
      </a>{" "}
      {/*<span className="bg-primary text-white hover:text-white">New!</span>*/}
    </div>
    <div className="flex gap-x-1 md:gap-x-2">
      <a href="https://mem.tech/ide" className={linkStyling}>
        IDE
      </a>{" "}
      {/*<span className="bg-primary text-white hover:text-white">New!</span>*/}
    </div>
    <div className="flex gap-x-1 md:gap-x-2">
      <a href="https://mem.tech/explorer" className={linkStyling}>
        Explorer
      </a>{" "}
      {/*<span className="bg-primary text-white hover:text-white">New!</span>*/}
    </div>
    <a
      href={DOCS_URL}
      className={linkStyling}
      rel="noreferrer noopener nofollow"
      target="_blank"
    >
      Docs
    </a>
    <a
      href={TWITTER_URL}
      className={linkStyling}
      rel="noreferrer noopener nofollow"
      target="_blank"
    >
      <svg
        className="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 24"
        aria-hidden="true"
        fill="currentColor"
      >
        <g>
          <path d="M14.258 10.152L23.176 0h-2.113l-7.747 8.813L7.133 0H0l9.352 13.328L0 23.973h2.113l8.176-9.309 6.531 9.309h7.133zm-2.895 3.293l-.949-1.328L2.875 1.56h3.246l6.086 8.523.945 1.328 7.91 11.078h-3.246zm0 0"></path>
        </g>
      </svg>
    </a>
  </>
);

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="navstyle bg-black/20">
      <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1 items-center">
          <a href="https://mem.tech" className="-m-1.5 p-1.5 text-white flex items-center">
            <img
              src="https://www.mem.tech/icons/mem/mem-logo-v2.svg"
              width={50}
              height={50}
              alt="MEM logo"
            />
            <p className="font-semibold text-3xl font-mono select-none ml-2">
              MEM
            </p>
          </a>
        </div>

        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-100"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex font-mono text-white text-lg lg:gap-x-12 mr-12">
          <URLS/>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-black/60 backdrop-blur-xl px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">MEM</span>
              <img
                className="h-8 w-auto"
                width={50}
                height={50}
                src='https://www.mem.tech/icons/mem/mem-logo-v2.svg'
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-300"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <URLS/>
              </div>
              { /* <div className="py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-100 hover:bg-gray-50"
                >
                  Log in
                </a>
              </div> */ }
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
